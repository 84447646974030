export default {
        EVENT: {
                APP_LAUNCH: "APP-LAUNCH",
                LOGIN_ENTER: "LOGIN-ENTER",
                LOGIN_OTP_INVOKE: "LOGIN-OTP-INVOKE",
                LOGIN_OTP_ENTER: "LOGIN-OTP-ENTER",
                LOGIN_SUCCESS: "LOGIN-SUCCESS",
                LOGIN_FAILED: "LOGIN-FAILED",
                LOGOUT: "LOGOUT",
                LOGOUT_FAILED: "LOGOUT-FAILED",
                HOME_BANNER_CLICKS: "HOME-BANNER-CLICKS",
                HOME_PAGE_VIEW: "HOME-PAGE-VIEW",
                HOME_CLICK: "HOME-CLICK",
                SEE_ALL: "SEE-ALL",
                VIEW_CONTENT_DETAIL: "VIEW-CONTENT-DETAIL",
                ADD_WATCHLIST: "ADD-WATCHLIST",
                PLAY_TRAILER: "PLAY-TRAILER",
                VIEW_WATCHLIST: "VIEW-WATCHLIST",
                VIEW_FAVORITE: "VIEW-FAVORITE",
                DELETE_FAVORITE: "DELETE-FAVORITE",
                LOGIN_OTP_RESEND: "LOGIN-OTP-RESEND",
                LOGIN_PASSWORD: "LOGIN-PASSWORD",
                LOGIN_FAILURE: "LOGIN-FAILURE",
                PLAY_CONTENT: "CONTENT-PLAY",
                INITIAL_BUFFER_TIME: "INITIAL-BUFFER-TIME",
                PAUSE_CONTENT: "PAUSE-CONTENT",
                RESUME_CONTENT: "RESUME-CONTENT",
                PLAYBACK_FAILURE: "PLAYBACK-FAILURE",
                LIST_DEVICES: "LIST-DEVICES",
                DEVICE_LISTING_VIEW: "DEVICE-LISTING-VIEW",
                DEVICE_LISTING_EXIT: "DEVICE-LISTING-EXIT",
                DEVICE_LIMIT_POPUP: "DEVICE-LIMIT-POPUP",
                MAX_DEVICE_LIMIT: "MAX-DEVICE-LIMIT",
                DEVICE_LISTING_REMOVE: "DEVICE-LISTING-REMOVE",
                REMOVE_DEVICE_POPUP: "DEVICE-REMOVE-CONFIRMATION-POPUP",
                REMOVE_DEVICE: "REMOVE-DEVICE",
                DEVICE_REMOVE_CONFIRM: "DEVICE-REMOVE-CONFIRM",
                DEVICE_REMOVE_ERROR: "DEVICE-REMOVE-ERROR",
                DEVICE_REMOVE_SKIP: "DEVICE-REMOVE-SKIP",
                REMOVE_WATCHLIST: "REMOVE-WATCHLIST",
                FORGOT_PASSWORD: "FORGOT-PASSWORD",
                PASSWORD_RESET_SUCCESS: "PASSWORD-RESET-SUCCESS",
                PASSWORD_RESET_FAILED: "PASSWORD-RESET-FAILED",
                UPDATE_PASSWORD_INVOKED: "UPDATE-PASSWORD-INVOKED",
                UPDATE_PASSWORD_SUCCESS: "UPDATE-PASSWORD-SUCCESS",
                UPDATE_PASSWORD_FAILED: "UPDATE-PASSWORD-FAILED",
                CREATE_PASSWORD_INVOKED: "CREATE-PASSWORD-INVOKED",
                CREATE_PASSWORD_OTP_RECEIVED: "CREATE-PASSWORD-OTP-RECEIVED",
                CREATE_PASSWORD_SUCCESS: "CREATE-PASSWORD-SUCCESS",
                CREATE_PASSWORD_FAILED: "CREATE-PASSWORD-FAILED",
                SEARCH_START: "SEARCH-START",
                SEARCH: "SEARCH",
                NO_SEARCH_RESULT: "NO-SEARCH-RESULT",
                SEARCH_INITIATE: "SEARCH-INITIATE",
                SEARCH_RESULT: "SEARCH-RESULT",
                SEARCH_HOME: "SEARCH-HOME",
                SEARCH_RESULT_CLICKS: "SEARCH-RESULT-CLICKS",
                SEARCH_HOME_CLICKS: "SEARCH-HOME-CLICKS",
                FILTER_TOGGLE: "FILTER-TOGGLE",
                PACK_SELECTION_INITIATE: "PACK-SELECTION-INITIATE",
                ADD_PACK_SKIP: "ADD-PACK-SKIP",
                ADD_PACK_CONTINUE: "ADD-PACK-CONTINUE",
                MY_PLAN_RENEW_PLAN: "MY-PLAN-RENEW-PLAN",
                MY_PLAN_VIEW: "MY-PLAN-VIEW",
                MY_PLAN_CHANGE_TENURE: "MY-PLAN-CHANGE-TENURE",
                MY_PLAN_RENEW_CHANGE_PLAN: "MY-PLAN-RENEW-CHANGE-PLAN",
                MY_PLAN_RENEW_OTHER_OPTIONS: "MY-PLAN-RENEW-OTHER-OPTIONS",
                MY_PLAN_CANCEL_PLAN: "MY-PLAN-CANCEL-PLAN",
                MY_PLAN_CHANGE_PLAN: "MY-PLAN-CHANGE-PLAN",
                MY_PLAN_CANCEL_PLAN_LATER: "MY-PLAN-CANCEL-PLAN-LATER",
                MY_PLAN_CANCEL_PLAN_PROCEED: "MY-PLAN-CANCEL-PLAN-PROCEED",
                MY_PLAN_DONT_CANCEL_PLAN: "MY-PLAN-DONT-CANCEL-PLAN",
                PACK_COMPARE_PLANS_VIEW: "PACK-COMPARE-PLANS-VIEW",
                PACK_COMPARE_PLANS_SELECTION: "PACK-COMPARE-PLANS-SELECTION",
                MY_PLAN_RENEW_CHANGE_TENURE: "MY-PLAN-RENEW-CHANGE-TENURE",
                PACK_SELECTION_VIEWS: "PACK-SELECTION-VIEWS",
                UPGRADE_POPUP_UPGRADE: "UPGRADE-POPUP-UPGRADE",
                SUBSCRIBE_POPUPP_SUBSCRIBE: "SUBSCRIBE-POPUP-SUBSCRIBE",
                SUBSCRIBE_POPUP_CANCEL: "SUBSCRIBE-POPUP-CANCEL",
                UPGRADE_POPUP_CANCEL: "UPGRADE-POPUP-CANCEL",
                UPGRADE_POPUP: "UPGRADE-POPUP",
                SUBSCRIBE_POPUP: "SUBSCRIBE-POPUP",
                SET_DEFAULT_PROFILE: "SET-DEFAULT-PROFILE",
                PACK_SELECTED: "PACK-SELECTED",
                PACK_TENURE_VIEW: "PACK-TENURE-VIEW",
                SUBSCRIBE_SUCCESS: "SUBSCRIBE-SUCCESS",
                START_FREE_TRIAL: "START-FREE-TRIAL",
                SUBSCRIBE_FAILED: "SUBSCRIBE-FAILED",
                CONTACT_US: "CONTACT-US",
                NOTIFICATION_SETTINGS: "NOTIFICATION-SETTINGS",
                TERMS_CONDITIONS: "TERMS-CONDITIONS",
                PRIVACY_POLICY: "PRIVACY-POLICY",
                EDIT_PROFILE: "EDIT-PROFILE",
                TRANSACTION_HISTORY: "TRANSACTION-HISTORY",
                RECHARGE_FAILED: "RECHARGE-FAILED",
                FAQ_VIEW: "FAQ-VIEW",
                UPDATE_PROFILE: "UPDATE-PROFILE",
                HOME_ACCOUNT: "HOME-ACCOUNT",
                SWITCH_PROFILE: "SWITCH-PROFILE",
                FTV_UPSELL_VIEW: "FTV-UPSELL-VIEW",
                FTV_UPSELL_CONVERTED: "FTV-UPSELL-CONVERTED",
                FTV_WO: "FTV-WO",
                MODIFY_PACK_INITIATE: "MODIFY-PACK-INITIATE",
                MODIFY_PACK_SUCCESS: "MODIFY-PACK-SUCCESS",
                ERROR: "ERROR",
                ERROR_CODE: "ERROR-CODE",
                ERROR_MESSAGE: "ERROR-MESSAGE",
                FREE_TRIAL_LATER: "FREE-TRIAL-LATER",
                PACK_CANCELLATION: "PACK-CANCELLATION",
                CANCELLATION_REVOKED: "CANCELLATION-REVOKED",
                PACK_CANCEL_SKIP: "PACK-CANCEL-SKIP",
                PACK_REVOKE_SKIP: "PACK-REVOKE-SKIP",
                NUDGE_CLICK: "NUDGE-CLICK",
                HC_SEARCH_START: "HC-SEARCH-START",
                HC_SEARCH: "HC-SEARCH",
                HC_NO_SEARCH_RESULT: "HC-NO-SEARCH-RESULT",
                HC_SEARCH_RESULT: "HC-SEARCH-RESULT",
                HC_FAQ_CLICK: "HC-FAQ-CLICK",
                HC_VIEW_ALL_VIDEOS: "HC-VIEW-ALL-VIDEOS",
                HELP_VIDEO_CLICK: "HELP-VIDEO-CLICK",
                HC_LIKE: "HC-LIKE",
                HC_DISLIKE: "HC-DISLIKE",
                HC_CHAT_FAQ: "HC-CHAT-FAQ",
                HC_CHAT_VIDEO: "HC-CHAT-VIDEO",
                HC_CHAT_DEFAULT: "HC-CHAT-DEFAULT",
                SHARE_CLICK: "SHARE-CLICK",
                WHATSAPP_SHARE_CLICK: "WHATSAPP-SHARE-CLICK",
                CONTENT_LANG_EXPAND: "CONTENT-LANG-EXPAND",
                CONTENT_LANG_SKIP: "CONTENT-LANG-SKIP",
                CONTENT_LANG_SELECT: "CONTENT-LANG-SELECT",
                CONTENT_PLAY_END: "CONTENT-PLAY-END",
                CONTENT_PLAY_FAIL: "CONTENT-PLAY-FAIL",
                LOGIN_PAGE_NOTNOW: "LOGIN-PAGE-NOTNOW",
                LOGIN_PAGE_VISIT: "LOGIN-PAGE-VISIT",
                LOGIN_PAGE_NEWLOGIN: "LOGIN-PAGE-NEWLOGIN",
                LOGIN_RMN_ENTER_INVALID: "LOGIN-RMN-ENTER-INVALID",
                LOGIN_LIC_AGREEMENT: "LOGIN-LIC-AGREEMENT",
                LOGIN_GET_OTP: "LOGIN-GET-OTP",
                LOGIN_RMN_ENTER: "LOGIN-RMN-ENTER",
                LOGIN_SUBSCRIPTIONID_SELECT: "LOGIN-SUBSCRIPTIONID-SELECT",
                LOGIN_MY_PLAN_STARTWATCHING: "LOGIN-MY-PLAN-STARTWATCHING",
                HERO_BANNER_CLICKS: "HERO-BANNER-CLICKS",
                BINGE_APP_LAUNCH: "BINGE-APP-LAUNCH",
                SEE_ALL_EPISODES: "SEE-ALL-EPISODES",
                APPS_RAIL_CLICK: "APPS-RAIL-CLICK",
                BROWSE_BY_LANGUAGE_RAIL_CLICK: "BROWSE-BY-LANGUAGE-RAIL-CLICK",
                BROWSE_BY_GENRE_RAIL_CLICK: "BROWSE-BY-GENRE-RAIL-CLICK",
                CONTENT_CLICK: "CONTENT-CLICK",
                TS_RECHARGE_INITIATE: "TP-RECHARGE-INITIATE",
                TS_RECHARGE_SUCCESS: "TP-RECHARGE-SUCCESS",
                TS_RECHARGE_FAILED: "TP-RECHARGE-FAILED",
                PAGE_CLICK: "PAGE-CLICK",
                MENU_CLICK: "MENU-CLICK",
                MENU_OPTION: "MENU-OPTION",
                SETTINGS_VISITS: "SETTINGS-VISITS",
                SETTINGS_MENU_OPTION: "SETTINGS-MENU-OPTION",
                SYNOPSIS_MORE_CLICK: "SYNOPSIS-MORE-CLICK",
                PAYMENT: "PAYMENT",
                SUBSCRIPTION_DRAWER_INITIATE: "SUBSCRIPTION-DRAWER-INITIATE",
                SUBSCRIPTION_DRAWER_DO_IT_LATER: "SUBSCRIPTION-DRAWER-DO-IT-LATER",
                SUBSCRIPTION_DRAWER_PROCEED: "SUBSCRIPTION-DRAWER-PROCEED",
                SUBSCRIPTION_DRAWER_EXISTING_USER_LOGIN: "SUBSCRIPTION-DRAWER-EXISTING-USER-LOGIN",
                SUBSCRIPTION_DRAWER_CLOSE: "SUBSCRIPTION-DRAWER-CLOSE",
                SUBSCRIPTION_PAGE_INITIATE: "SUBSCRIPTION-PAGE-INITIATE",
                SUBSCRIPTION_PAGE_PROCEED: "SUBSCRIPTION-PAGE-PROCEED",
                DEVICE_LIMIT_REVIEW: "DEVICE-LIMIT-REVIEW",
                DEVICE_LIMIT_SKIP: "DEVICE-LIMIT-SKIP",
                CONTENT_LANG_OPEN: "CONTENT-LANG-OPEN",
                CONTENT_LANG_PROCEED: "CONTENT-LANG-PROCEED",
                VIEW_HERO_BANNER: "VIEW-HERO-BANNER",
                PAYMENT_INITIATE: "PAYMENT-INITIATE",
                SUBSCRIPTION_INITIATE: "SUBSCRIPTION-INITIATE",
                MENU_CATEGORY: "MENU-CATEGORY",
                MENU_BOTTOM: "MENU-BOTTOM",
                SEARCH_RESULT_SWIPE: "SEARCH-RESULT-SWIPE",
                HC_HAMBURGER_CLICK: "HC-HAMBURGER-CLICK",
                HC_OUTAGE_BANNER_CLICK: "HC-OUTAGE-BANNER-CLICK",
                HC_URL_VISIT: "HC-URL-VISIT",
                HC_TICKET_VIEW_DETAIL: "HC-TICKET-VIEW-DETAIL",
                HC_TICKET_VIEW_PAST_REQUEST: "HC-TICKET-VIEW-PAST-REQUEST",
                HC_TRACK_REQUEST_CLICK: "HC-TRACK-REQUEST-CLICK",
                HC_VIEW_REQUEST_HISTORY_CLICK: "HC-VIEW-REQUEST-HISTORY-CLICK",
                CUSTOM_SUBSCRIPTION_PAGE_INITIATE: "CUSTOM-SUBSCRIPTION-PAGE-INITIATE",
                CUSTOM_SUBSCRIPTION_PROCEED: "CUSTOM-SUBSCRIPTION-PROCEED",
                CUSTOM_SUBSCRIPTION_EXPLORE_PLANS: "CUSTOM-SUBSCRIPTION-EXPLORE-PLANS",
                LOGIN_SKIP: "LOGIN-SKIP",
                DISCOUNTING_PAGE_LOAD: "DISCOUNTING-PAGE-LOAD",
                SUB_DRAWER_LAUNCH_MYOP: "SUB-DRAWER-LAUNCH-MYOP",
                SUBSCRIPTION_DRAWER_MYOP_SELECT: "SUBSCRIPTION-DRAWER-MYOP-SELECT",
                SUBSCRIPTION_DRAWER_CURATED_SELECT: "SUBSCRIPTION-DRAWER-CURATED-SELECT",
                SUBSCRIPTION_DRAWER_EXPLORE: "SUBSCRIPTION-DRAWER-EXPLORE",
                SUB_DRAWER_DO_IT_LATER_MYOP: "SUB-DRAWER-DO-IT-LATER-MYOP",
                SUB_DRAWER_CLOSE_MYOP: "SUB-DRAWER-CLOSE-MYOP",
                VIEW_RAIL_CONTENT: "VIEW-RAIL-CONTENT",
                PAYMENT_FLOW_EXIT: "PAYMENT-FLOW-EXIT",
                HC_REOPEN_TICKET: "HC-REOPEN-TICKET",
                HC_CHAT_FAIL: "HC-CHAT-FAIL",
                AUTO_SUGGESTION_INITIATE: 'AUTO-SUGGESTION-INITIATE',
                AUTO_SUGGESTION_SCROLLED: 'AUTO-SUGGESTION-SCROLLED',
                AUTO_SUGGESTION_CLICKED: 'AUTO-SUGGESTION-CLICKED',
                GAME_CLICK: 'GAME-CLICK',
                GAME_BROWSE_BY_GENRE_RAIL_CLICK: 'GAME-BROWSE-BY-GENRE-RAIL-CLICK',
                GAMES_VIEW_HERO_BANNER: 'GAMES-VIEW-HERO-BANNER',
                GAME_REMOVE_FAVOURITE: 'GAME-REMOVE-FAVOURITE',
                GAME_ADD_FAVOURITE: 'GAME-ADD-FAVOURITE',
                GAMES_HORIZONTAL_SWIPE: 'HORIZONTAL SWIPE',
                LINK_APPLE_TV_ACCOUNT_CLICK: 'LINK-APPLE-TV-ACCOUNT-CLICK',
                APPLE_ACTIVATE_NOW_CLICK: 'APPLE-ACTIVATE-NOW-CLICK',
                ACTIVATE_APPLE_TV_SUBSCRIPTION_CLICK: 'ACTIVATE-APPLE-TV-SUBSCRIPTION-CLICK',
                PAYMENT_RETRY_PROCEED: 'PAYMENT-RETRY-PROCEED',
                PAYMENT_RETRY_POPUP: 'PAYMENT-RETRY-POPUP',
                PAYMENT_RETRY_CANCEL: ' PAYMENT-RETRY-CANCEL',
                ZEE5_TAG_API_FAILURE: 'ZEE5-TAG-API-FAILURE',
                ZEE5_TAG_API_SUCCESS: 'ZEE5-TAG-API-SUCCESS',
                HEROBANNER_PLAYNOW: 'HEROBANNER-PLAYNOW',
                SUBSCRIBE_SUCCESS_FTV_UPSELL_VIEW: 'SUBSCRIBE-SUCCESS-FTV-UPSELL-VIEW',
                SUBSCRIBE_SUCCESS_FTV_UPSELL_PROCEED: 'SUBSCRIBE-SUCCESS-FTV-UPSELL-PROCEED',
                SUBSCRIBE_SUCCESS_FTV_UPSELL_NOTNOW: 'SUBSCRIBE-SUCCESS-FTV-UPSELL-NOT-NOW',
                DOWNLOADNUDGE_CLICK: 'DOWNLOADNUDGE-CLICK',
                DOWNLOADNUDGE_CLOSE: 'DOWNLOADNUDGE-CLOSE',
                HOME_SUBSCRIBE_CLICK: 'HOME-SUBSCRIBE-CLICK',
                HELP_CLICKED: 'HELP-CLICKED',
                HELP_CLOSED: 'HELP-CLOSED',
                APPLE_PLAY_CTA_CLICK: 'APPLE-PLAY-CTA-CLICK',
                HC_LIKE_FEEDBACK: "HC-LIKE-FEEDBACK",
                HC_DISLIKE_FEEDBACK: "HC-DISLIKE-FEEDBACK",
                HC_VIDEO_AUTO_PLAY: "HC-VIDEO-AUTO-PLAY",
                HC_VIDEO_PLAY_DURATION: "HC-VIDEO-PLAY-DURATION",
                HC_VIDEO_PLAY_EXIT: "HC-VIDEO-PLAY-EXIT",
                PAGE_VIEW: 'PAGE-VIEW',
                APP_REPLACEMENT_CTA: 'APP-REPLACEMENT-CTA',
                APP_REPLACEMENT_NUDGE_VIEW: 'APP-REPLACEMENT-NUDGE-VIEW',
                APP_REPLACEMENT_NUDGE_LATER: 'APP-REPLACEMENT-NUDGE-LATER',
                APP_REPLACEMENT_NUDGE_PROCEED: 'APP-REPLACEMENT-NUDGE-PROCEED',
                PLAN_UPGRADE_CONFIRMATION_VIEW: 'PLAN-UPGRADE-CONFIRMATION-VIEW',
                PLAN_UPGRADE_CONFIRMATION_NOTNOW: 'PLAN-UPGRADE-CONFIRMATION-NOTNOW',
                PLAN_UPGRADE_CONFIRMATION_PROCEED: 'PLAN-UPGRADE-CONFIRMATION-PROCEED',
                LOGIN_BINGEID_PAGE_VISIT: "LOGIN-BINGEID-PAGE-VISIT",
                LOGIN_BINGEID_PAGE_PROCEED: "LOGIN-BINGEID-PAGE-PROCEED",
                REGULAR_APPS_RAIL_CLICK: 'REGULAR-APPS-RAIL-CLICK',
                VIEW_APP_CONTENT: 'VIEW-APP-CONTENT',
                BINGELIST_SELECT: 'BINGELIST-SELECT',
                BINGELIST_CANCEL: 'BINGELIST-CANCEL',
                BINGELIST_REMOVE: 'BINGELIST-REMOVE',
                LOGIN_OTP_PAGE_VISIT: "LOGIN-OTP-PAGE-VISIT",
                LOGIN_OTP_RECEIVED: "LOGIN-OTP-RECEIVED",
                LOGIN_OTP_PAGE_BACK: "LOGIN-OTP-PAGE-BACK",
                LOGIN_EXIT: "LOGIN-EXIT",
                PRIME_INTERSTITIAL_PAGE_VIEWED: 'PRIME-INTERSTITIAL-PAGE-VIEWED',
                CLAIM_PRIME_FROM_BINGE: 'CLAIM-PRIME-FROM-BINGE',
                WATCH_WITH_EXISTING_PRIME: "WATCH-WITH-EXISTING-PRIME",
                PRIME_ACTIVATED: 'PRIME-ACTIVATED',
                PRIME_REDIRECTION_POPUP: 'PRIME-REDIRECTION-POPUP',
                PRIME_REDIRECTION_POPUP_PROCEED: 'PRIME-REDIRECTION-POPUP-PROCEED',
                ACTIVATE_PRIME: 'ACTIVATE-PRIME',
                ZERO_APPS_NUDGE_VIEW: 'ZERO-APPS-NUDGE-VIEW',
                ZERO_APPS_NUDGE_CLOSE: 'ZERO-APPS-NUDGE-CLOSE',
                ZERO_APPS_NUDGE_CHOOSEAPPS: 'ZERO-APPS-NUDGE-CHOOSEAPPS',
                APPS_ADDED_SUCCESS_VIEW: 'APPS-ADDED-SUCCESS-VIEW',
                APPS_ADDED_SUCCESS_DONE: 'APPS-ADDED-SUCCESS-DONE',
                RENEW_NUDGE_VIEW: 'RENEW-NUDGE-VIEW',
                RENEW_NUDGE_CLOSE: 'RENEW-NUDGE-CLOSE',
                RENEW_NUDGE_CLICK: 'RENEW-NUDGE-CLICK',
                DELETE_ACCOUNT_FAQ_CLICK: 'DELETE-ACCOUNT-FAQ-CLICK',
                DELETE_ACCOUNT_PROCEED: 'DELETE-ACCOUNT-PROCEED',
                DELETE_ACCOUNT_GET_OTP: 'DELETE-ACCOUNT-GET-OTP',
                DELETE_ACCOUNT_OTP_VALIDATED: 'DELETE-ACCOUNT-OTP-VALIDATED',
                DELETE_ACCOUNT_CONFIRM: 'DELETE-ACCOUNT-CONFIRM',
                APP_REPLACEMENT_CTA_CHOOSEAPPS: 'APP-REPLACEMENT-CTA-CHOOSEAPPS',
                APPS_ACTIVATION_POPUP: 'APPS-ACTIVATION-POPUP',
                APPS_ACTIVATION_POPUP_EXIT: 'APPS-ACTIVATION-POPUP-EXIT',
                COUPON_REDEEM_PAGE_VISIT: 'COUPON-REDEEM-PAGE-VISIT',
                COUPON_CODE_ENTER: "COUPON-CODE-ENTER",
                ACTIVATE_APPLE_TV_POPUP: 'ACTIVATE-APPLE-TV-POPUP',
                APPLE_INTERSTITIAL_SCREEN_VIEWED: 'APPLE-INTERSTITIAL-SCREEN-VIEWED',
                ERROR_POPUP: 'ERROR-POPUP',
                PRIME_REDIRECTION_POPUP_FORGOTACCOUNT: 'PRIME-REDIRECTION-POPUP-FORGOTACCOUNT',
                PRIME_FORGOTACCOUNT_FAIL: ' PRIME-FORGOTACCOUNT-FAIL',
                PRIME_FORGOTACCOUNT_RETRY: 'PRIME-FORGOTACCOUNT-RETRY ',
                LA_NOT_TRIGGERED: 'LA-NOT-TRIGGERED',
                EVENT_ROVER_CONNECTED: "EVENT-ROVER-CONNECTED",
                EVENT_ROVER_DISCONNECTED: "EVENT-ROVER-DISCONNECTED",
                EVENT_ROVER_CONNECTION_FAILED: "EVENT-ROVER-CONNECTION-FAILED",
                JUSPAY_INITIATE: 'JUSPAY-INITIATE'
        },
        VALUE: {
                BINGE: "BINGE",
                SEARCH_BAR: "Searchbar",
                TATA_SKY_SUBSCRIBER: "TATA SKY SUBSCRIBER",
                GUEST: "GUEST",
                YES: "YES",
                CAPITALIZED_YES: "YES",
                CAPITALIZED_NO: "NO",
                NO: "NO",
                HOME: "Home",
                MOVIES: "Movies",
                TV_SHOWS: "TV Shows",
                SPORTS: "Sports",
                WATCHLIST: "Watchlist",
                OTP: "OTP",
                PASSWORD: "Password",
                HERO: "Hero",
                RAIL: "Rail",
                EDITORIAL: "EDITORIAL",
                RECOMMENDATION: "Recommendation",
                BRAND: "BRAND",
                SERIES: "Series",
                TVOD: "TVOD",
                WEB_SHORTS: "Web Shorts",
                CONTENT_DETAIL: "Content-Detail",
                PARTNER_HOME: "Partner Home",
                MIX: "MIX",
                SEARCH_TYPE: "Text",
                SEARCH_SOURCE_MANUAL: "Manual",
                SEARCH_SOURCE_RECENT_SEARCH: "Recent Search",
                SEARCH_PAGE: "Search Page",
                RESULT_PAGE: "Result Page",
                APP_LAUNCH: "APP-LAUNCH",
                RMN: "RMN",
                SID: "SID",
                SEARCH: "SEARCH",
                FAILED: "Failed",
                LOGIN: "Login",
                DEVICE_MANAGEMENT: "DeviceManagement",
                CONTENT: "Content",
                MY_ACCOUNT: "My Account",
                SEE_ALL: "See-All",
                BROWSE_BY_LANGUAGE: "BROWSE-BY-LANGUAGE",
                BROWSE_BY_GENRE: "BROWSE-BY-GENRE",
                BRAND_SEASON: "Brand-Season",
                UNSUBSCRIBED: "UNSUBSCRIBED",
                INSTALLER_FSD: "FSD",
                INSTALLER_DIY: "DIY",
                MBR: "MBR",
                DBR: "DBR",
                FRESH: "FRESH",
                RENEW: "RENEW",
                UPGRADE: "UPGRADE",
                DOWNGRADE: "DOWNGRADE",
                PI_PAGE: "PI-PAGE",
                PLAYER: "PLAYER",
                SUBSCRIBED: "Subscribed",
                FREEMIUM: "FREEMIUM",
                UNSUBSCRIBED_SMALL: "Unsubscribed",
                MY_SUBSCRIPTION: "My Subscription",
                HOMESCREEN: "Homescreen",
                HC_RESENT_SEARCH: "Recent Search",
                HC_AUTO_COMPLETE: "Auto-Complete",
                HC_MANUAL: "Manual",
                NUDGE: "NUDGE",
                LANGUAGE: "LANGUAGE",
                HOME_BANNER: "HOME_BANNER",
                REGULAR_RAIL: "REGULAR",
                REGULAR: "REGULAR",
                ALL: "ALL",
                MOBILE: "MOBILE",
                WEB: 'WEB',
                MY_PLAN: "MY-PLAN",
                CHANGE_PLAN: "CHANGE-PLAN",
                GO_VIP: 'GO-VIP',
                SUBSCRIBE: "SUBSCRIBE",
                PI_DETAIL: "PI-SCREEN",
                HAMBURGER: "HAMBURGER",
                HAMBURGER_MENU: "HAMBURGERMENU",
                TSWALLET: "TSWALLET",
                PG: "PG",
                OTHERS: 'OTHERS',
                EPISODE: "EPISODE",
                RECURRING: "RECURRING",
                ONE_TIME: "ONETIME",
                COMPLETED: "COMPLETED",
                CONTENT_PLAYBACK: "content-playback",
                NON_TP: "NON-TP",
                TP: "TP",
                PAID: "PAID",
                FREE: "Free",
                HERO_BANNER: "HERO-BANNER",
                DISCOUNTING_PAGE: "DISCOUNTING PAGE",
                DEFAULT_TENURE: "MONTHLY",
                DEFAULT: "DEFAULT",
                M_SALES: "mSales",
                ALL_CONTENT: "ALL CONTENT",
                TICK_TICK_APP_LAUNCH: "APP-LAUNCH",
                SUCCESS: "SUCCESS",
                FAILURE: "FAILURE",
                PENDING: "PENDING",
                NOT_SELECTED: "NOT-SELECTED",
                PAYMENT_GATEWAY: "PAYMENT-GATEWAY",
                TP_WALLET: "TP-WALLET",
                NOT_ATTEMPTED: "NOT-ATTEMPTED",
                BACKPRESSED: "BACKPRESSED",
                NOT_FOUND: "NOT-FOUND",
                SEASONS: "Seasons",
                CAMPAIGN: "CAMPAIGN",
                IOS: "iOS",
                ANDROID: "Android",
                FIBRE: "Fiber",
                SI_MANDATORY: "SI-MANDATORY",
                TATAPLAY: 'TATAPLAY',
                SCROLL_DOWN: 'DOWN',
                KEYBOARD_STATE_OPEN: 'OPEN',
                KEYBOARD_STATE_CLOSED: 'CLOSED',
                GAMES: 'Games',
                GENRE_RAIL_FOR_GAMES: "GENRE_RAIL_FOR_GAMES",
                NULL: 'Null',
                GAMEZOP: 'Gamezop',
                TRANSACTION_FAILED: 'Transaction Failed',
                TRANSACTION_PENDING: 'Transaction Pending',
                SEARCH_SUGGESTION: 'SEARCH-SUGGESTIONS',
                SEARCH_RESULTS: 'SEARCH-RESULTS',
                COMVIVA: 'COMVIVA',
                SIEBEL: 'SIEBEL',
                FIRST_TIME: 'FIRST_TIME',
                DEFAULT_AMOUNT_VALUE: '0.00',
                LANDSCAPE: 'LANDSCAPE',
                APPSTORE: 'APPSTORE',
                PLAYSTORE: 'PLAYSTORE',
                HEADER: 'HEADER',
                FOOTER: 'FOOTER',
                TA_RELATED_RAIL: "TA Related Rail",
                EDITORIAL_VERBIAGE: 'Editorial',
                EDITORIAL_HB: "Editorial HB",
                TA_HB: 'TA HB',
                SPECIAL_RAIL: 'Special Rail',
                SHOWS: 'Shows',
                SEARCH_SUGGESTION_VERBIAGE: 'Search Suggestion',
                SEARCH_RESULT_VERBIAGE: 'Search Results',
                TRENDING: 'Trending',
                NA: 'NA',
                APPLE_NOT_CLAIMED: 'Apple Not Claimed',
                APPLE_PENDING: 'Apple Pending',
                DEFAULT_LOWERCASE: 'Default',
                NOT_INITIATED: 'Not Initiated',
                CODE_GENERATED: 'CODE-GENERATED',
                CODE_REDEEMDED: 'CODE-REDEEMDED',
                PI_PAGE_LOWERCASE: 'PI Page',
                ACTIVATION_POPUP: 'Activation Popup',
                APPLE_ACTIVATION_VIDEO: 'Apple Activation Video',
                PLAY_POPUP: 'Play Popup',
                HELP_POPUP: 'Help Popup',
                VIDEO_DETAIL_SCREEN: "VIDEO-DETAIL-SCREEN",
                DEEPLINK: "DEEPLINK",
                HC_BACK_PRESS: "BACK-PRESS",
                HC_CHATNOW: "CHATNOW",
                AUTO: 'AUTO',
                APP_REPLACEMENT_SUCCESS_PAGE: 'APP-REPLACEMENT-SUCCESS-PAGE',
                UPGRADE_YOUR_PLAN: "UPGRADE-YOUR-PLAN",
                PLAYBACK: "PLAYBACK",
                SUBSCRIPTIONDRAWER_EXISTINGUSER: "SUBSCRIPTIONDRAWER-EXISTINGUSER",
                SUBSCRIPTIONPAGE_EXISTINGUSER: "SUBSCRIPTIONPAGE-EXISTINGUSER",
                SUBSCRIPTIONPAGE_PLANSELECTION: "SUBSCRIPTIONPAGE-PLANSELECTION",
                LOGO_CLICK: 'LOGO-CLICK',
                EXPLORE_CTA_CLICK: 'EXPLORE-CTA-CLICK',
                BINGELIST_PAGE: 'BINGELIST-PAGE',
                GAME: "GAME",
                LOGIN_EXISTINGRMN_PAGE: "LOGIN_EXISTINGRMN_PAGE",
                LOGIN_RMNENTRY_PAGE: "LOGIN-RMNENTRY-PAGE",
                LOGIN_OTP_PAGE: "LOGIN-OTP-PAGE",
                LOGIN_BINGESELECT_PAGE: "LOGIN-BINGESELECT-PAGE",
                LOGIN_DEVICELIMITPOPUP: "LOGIN-DEVICELIMITPOPUP",
                LOGIN_MANAGEDEVICES_PAGE: "LOGIN-MANAGEDEVICES-PAGE",
                ADD_TO_BINGE_LIST: "ADD-TO-BINGE-LIST",
                BINGE_LIST: "BINGE-LIST",
                SETTINGS: "SETTINGS",
                HAMBURGER_SUBSCRIBE_CTA: "HAMBURGER-SUBSCRIBE-CTA",
                HOME_SUBSCRIBE_CTA: "  HOME-SUBSCRIBE-CTA",
                NO_INPUT: 'NO-INPUT',
                LIVE_TV: "LiveTV",
                LARGE_SCREEN: 'LS',
                APPLAUNCH: 'APPLAUNCH',
                LOGIN: 'LOGIN',
                CONTENTPLAYBACK: 'CONTENTPLAYBACK',
                MYPLAN: 'MYPLAN',
                BEFORE_EXPIRY: 'BEFORE-EXPIRY',
                AFTER_EXPIRY: 'AFTER-EXPIRY',
                SUBSCRIBE_SUCCESS: 'SUBSCRIBE-SUCCESS',
                PRIME: 'Prime',
                APPLE_TV: 'AppleTv',
                REACTIVATION: 'REACTIVATION',
                MIGRATION: 'MIGRATION',
                ACTIVATION: 'ACTIVATION',
                REASON: "FAILED",
                SERIES_ID_NOT_FOUND: 'Series-ID not found or Orphan content',
                HOME_NUDGE: 'HOME-NUDGE',
                INTERSTITIAL_SCREEN: 'INTERSTITIAL-SCREEN',
        },
        PARAMETER: {
                SOURCE: "SOURCE",
                STATE: "STATE",
                TITLE_SECTION: "TITLE-SECTION",
                TYPE: "TYPE",
                NAME: "NAME",
                SECTION: "SECTION",
                HERO_BANNER_NUMBER: "HERO-BANNER-NUMBER",
                CONFIG_TYPE: "CONFIG-TYPE",
                CONTENT_TYPE: "CONTENT-TYPE",
                PARTNER: "PARTNER",
                CONTENT_PARTNER: "CONTENT-PARTNER",
                RAIL_TITLE: "RAIL-TITLE",
                CONTENT_TITLE: "CONTENT-TITLE",
                GENRE: "GENRE",
                RAIL_POSITION: "RAIL-POSITION",
                CONTENT_POSITION: "CONTENT-POSITION",
                CONTENT: 'CONTENT',
                POSITION: 'POSITION',
                PARTNER_HOME: "PARTNER-HOME",
                PAGE_NAME: "PAGE-NAME",
                REASON: "REASON",
                QR_SOURCE: 'QR-SOURCE',
                CONTENT_GENRE: "CONTENT-GENRE",
                CONTENT_GENRE_PRIMARY: "CONTENT-GENRE-PRIMARY",
                DURATION_MINUTES: "WATCH-DURATION-MINUTES",
                DURATION_SECONDS: "WATCH-DURATION-SECONDS",
                NUMBER_OF_RESUME: "NUMBER-OF-RESUME",
                NUMBER_OF_PAUSE: "NUMBER-OF-PAUSE",
                BUFFER_DURATION_MINUTES: "BUFFER-DURATION-MINUTES",
                BUFFER_DURATION_SECONDS: "BUFFER-DURATION-SECONDS",
                START_TIME: "START-TIME",
                STOP_TIME: "STOP-TIME",
                KEYWORD: "KEYWORD",
                SEARCH_TYPE: "SEARCH-TYPE",
                SEARCH_SOURCE: "SOURCE",
                SCREEN_NAME: "SCREEN-NAME",
                SEARCH_COUNT: "SEARCH-COUNT",
                RECENT_SEARCH: "RECENT-SEARCH",
                AUTH: "AUTH",
                RELEASE: "RELEASE",
                SUBSCRIBED: "SUBSCRIBED",
                RAIL: "RAIL-TITLE",
                ORIGIN: "ORIGIN",
                PACK_NAME: "PACK-NAME",
                PACK_TYPE: "PACK-TYPE",
                PACK_PRICE: "PACK-PRICE",
                PACK_ACTIVE: "PACK-ACTIVE",
                DAYS_REMAINING: "DAYS-REMAINING",
                PARTNER_NAME: "PARTNER-NAME",
                AMOUNT: "AMOUNT",
                VALUE: "VALUE",
                TO_BAID: "TO-BAID",
                SID: "SID",
                CONTENT_LANGUAGE: "CONTENT-LANGUAGE",
                CONTENT_LANGUAGE_PRIMARY: "CONTENT-LANGUAGE-PRIMARY",
                FILTER_LANGUAGE: "FILTER-LANGUAGE",
                UPGRADED_PACK_PRICE: "UPGRADED-PACK-PRICE",
                ORDERED_FTV: "ORDERED-FTV",
                FTV_ORDER_TYPE: "FTV-ORDER-TYPE",
                MOD_TYPE: "MOD-TYPE",
                ERROR_CODE: "ERROR-CODE",
                ERROR_MESSAGE: "ERROR-MESSAGE",
                VIEW_TYPE: "VIEW-TYPE",
                IS_FROM_NUDGE: "IS-FROM-NUDGE",
                FDO_REGISTERED: "FDO-REGISTERED",
                FDR_RAISED_ON_DAY: "FDR-RAISED-ON-DAY",
                BINGE_RAISED_ON: "BINGE-RAISED-ON",
                PRIME_RAISED_ON: "PRIME-RAISED-ON",
                BINGE_CANCELLATION_DATE: "BINGE-CANCELLATION-DATE",
                PRIME_CANCELLATION_DATE: "PRIME-CANCELLATION-DATE",
                BINGE_CANCELLATION: "BINGE-CANCELLATION",
                PRIME_CANCELLATION: "PRIME-CANCELLATION",
                REVOKED_ON: "REVOKED-ON",
                DTH_STATUS: "DTH-STATUS",
                COUNTER_VALUE: "COUNTER-VALUE",
                DISPLAYED_ON_DAY: "DISPLAYED-ON-DAY",
                FREE_TRIAL_AVAILED: "FREE-TRIAL-AVAILED",
                PHRASE: "PHRASE",
                TITLE: "TITLE",
                ACTOR: "ACTORS",
                FREE_CONTENT: "FREE-CONTENT",
                CONTENT_RATING: "CONTENT-RATING",
                RELEASE_YEAR: "RELEASE-YEAR",
                DEVICE_TYPE: "DEVICE-TYPE",
                SUBSCRIPTION_ID: "SUBSCRIPTION-ID",
                PRIME_PACK_ACTIVE: "PRIME-PACK-ACTIVE",
                DEVICE: "DEVICE",
                DEVICE_LIST: "DEVICE-LIST",
                TIMESTAMP: "TIMESTAMP",
                USER_STATE: "USER-STATE",
                RAIL_TYPE: "RAIL-TYPE",
                RAIL_CATEGORY: "RAIL-CATEGORY",
                CONTENT_AUTH: "CONTENT-AUTH",
                CONTENT_CATEGORY: "CONTENT-CATEGORY",
                CONTENT_PARENT_TITLE: "CONTENT-PARENT-TITLE",
                LIVE_CONTENT: "LIVE-CONTENT",
                PAYMENT_TYPE: "PAYMENT-TYPE",
                PAYMENT_METHOD: "PAYMENT-METHOD",
                PAYABLE_AMOUNT: "PAYABLE-AMOUNT",
                CHANGE_PLAN: "CHANGE-PLAN",
                CHANGE_TENURE: "CHANGE-TENURE",
                DURATION: "DURATION",
                AUTO_PLAYED: "AUTO-PLAYED",
                VIDEO_QUALITY: "VIDEO-QUALITY",
                SEEK_BAR_PROGRESS: "SEEK-BAR-PROGRESS",
                CONTENT_PARTNER_SELECTED: "CONTENT-PARTNER-SELECTED",
                CONTENT_PARTNER_POSITION: "CONTENT-PARTNER-POSITION",
                LANGUAGE_SELECTED: "LANGUAGE-SELECTED",
                RAIL_LANGUAGE_POSITION: "RAIL-LANGUAGE-POSITION",
                RAIL_GENRE_POSITION: "RAIL-GENRE-POSITION",
                GENRE_SELECTED: "GENRE-SELECTED",
                FILTER_GENRE: "FILTER-GENRE",
                PAGE_RESULT_SWIPE: "PAGE-RESULT-SWIPE",
                FILTER_SELECTED: "FILTER-SELECTED",
                FAQ_ID: "FAQ-ID",
                VIDEO_ID: "VIDEO-ID",
                FIRST_TIME: "FIRST-TIME",
                DEVICE_METHOD: "DEVICE-METHOD",
                TRANSACTION_STATUS: "TRANSACTION-STATUS",
                FAILURE_REASON: "FAILURE-REASON",
                PLATFORM: "PLATFORM",
                DEVICE_ID: "DEVICE-ID",
                C_ID: "C-ID",
                TS_SID: "TS-SID",
                PACK_AMOUNT: "PACK-AMOUNT",
                RENEWAL_MODE: "RENEWAL-MODE",
                SUBSCRIBER_BALANCE: "SUBSCRIBER-BALANCE",
                CARD_TYPE: "CARD-TYPE",
                BANK_NAME: "BANK-NAME",
                TRANSACTION_ID: "TRANSACTION-ID",
                WALLET_NAME: "WALLET-NAME",
                CARD_NAME: "CARD-NAME",
                FIRST_SUBSCRIPTION: "FIRST-SUBSCRIPTION",
                PACK_TENURE: "PACK-TENURE",
                EXISTING_PACK_TENURE: "EXISTING-PACK-TENURE",
                EXISTING_PACK_PRICE: "EXISTING-PACK-PRICE",
                EXISTING_PACK_TYPE: "EXISTING-PACK-TYPE",
                EXISTING_PACK_NAME: "EXISTING-PACK-NAME",
                OPTION: "OPTION",
                CATEGORY_NAME: "CATEGORY-NAME",
                USER_TYPE: 'USER-TYPE',
                ERROR: "ERROR",
                RMN: "RMN",
                DETAILS: "DETAILS",
                TENURE: "TENURE",
                SUBSCRIPTION_DRAWER_CLOSE: "SUBSCRIPTION-DRAWER-CLOSE",
                PACK_ID: "PACK-ID",
                PAYMENT_STATUS: "PAYMENT-STATUS",
                PG_RESPONSE_CODE: "PG-RESPONSE-CODE",
                EXIT_STATUS: "EXIT-STATUS",
                TICKET_ID: "TICKET-ID",
                ACQUISITION_SOURCE: "ACQUISITION-SOURCE",
                EXPERIMENT_TG: "EXPERIMENT-TG",
                MATCHES: 'MATCHES',
                MATCH_COUNT: 'MATCH-COUNT',
                SCROLL_DIRECTION: 'SCROLL-DIRECTION',
                KEYBOARD_STATE: 'KEYBOARD-STATE',
                CLICK_POSITION: 'CLICK-POSITION',
                SUGGESTOR_TYPE: 'SUGGESTOR-TYPE',
                CONTENT_ID: 'CONTENT-ID',
                SEARCH_KEYWORD: 'SEARCH-KEYWORD',
                GAME_GENRE: 'GAME-GENRE',
                GAME_PARTNER: 'GAME-PARTNER',
                GAME_POSITION: 'GAME-POSITION',
                GAME_RATING: 'GAME-RATING',
                GAME_TITLE: 'GAME-TITLE',
                FREE_GAME: 'FREE-GAME',
                ZEE5TAG: 'Zee5Tag',
                APPLE_COUPON_STATUS: 'APPLE-COUPON-STATUS',
                UTM_SOURCE: 'UTM_Source',
                UTM_MEDIUM: 'UTM_Medium',
                UTM_CAMPAIGN: 'UTM_Campaign',
                UTM_CONTENT: 'UTM_Content',
                UTM_TERM: 'UTM_Term',
                PACK_START_DATE: 'PACK-START-DATE',
                PACK_END_DATE: 'PACK-END-DATE',
                ORDER_ID: 'ORDER-ID',
                STACK: 'STACK',
                CHANNEL: 'CHANNEL',
                PAYMENT_INSTRUMENT: 'PAYMENT-INSTRUMENT',
                PURCHASE_TYPE: 'PURCHASE-TYPE',
                MODIFICATION_TYPE: 'MODIFICATION-TYPE',
                PAYMENT_MODE: 'PAYMENT-MODE',
                REQUEST_PARAMS: 'REQUEST-PARAMS',
                API_URL: 'API-URL',
                DEEPLINK_URL: 'DEEPLINK-URL',
                TAG_VALUE: 'TAG-VALUE',
                USER_IDENTITY: 'USER-IDENTITY',
                REDEEMPTION_DATE: 'REDEEMPTION-DATE',
                CLAIMED_DATE: 'CLAIMED-DATE',
                CHEVRON_ICON: 'CHEVRON-ICON',
                VIEW_ALL_VIDEOS_CARD: 'VIEW-ALL-VIDEOS-CARD',
                HERO_BANNER_ORIENTATION: 'HERO-BANNER-ORIENTATION',
                STORE_NAME: 'STORE-NAME',
                NUDGE_TYPE: 'NUDGE-TYPE',
                TA_USECASE_ID: 'TA-USECASE-ID',
                PI_PAGE_NAME: 'PI-PAGE-NAME',
                APPLE_STATUS: 'APPLE-STATUS',
                HELP_TYPE: 'HELP-TYPE',
                PLAY_DURATION: 'PLAY-DURATION',
                VIA_POPUP: 'VIA-POPUP',
                FEEDBACK_COMMENT: "FEEDBACK-COMMENT",
                WATCHED_DURATION: "WATCHED-DURATION",
                TOTAL_VIDEO_DURATION: "TOTAL-VIDEO-DURATION",
                EXIT_SOURCE: "EXIT-SOURCE",
                PLAY_TYPE: 'PLAY-TYPE',
                PI_SKIP: 'PI-SKIP',
                LANGUAGE: 'LANGUAGE',
                OFFER_ELIGIBILITY: 'OFFER-ELIGIBILITY',
                SELECTED_PACK_TYPE: 'SELECTED-PACK-TYPE',
                OFFER_CARD_SHOWN: 'OFFER-CARD-SHOWN',
                APPS_SELECTED: 'APPS-SELECTED',
                DROPPED_APP: 'DROPPED-APP',
                BINGE_ID: "BINGE-ID",
                ALIAS_NAME: "ALIAS-NAME",
                PACK_STATUS: "PACK-STATUS",
                SUB_ID: "SUB-ID",
                CLICK_TYPE: 'CLICK-TYPE',
                APP_NAME: 'APP-NAME',
                BINGE_LIST_COUNT: 'BINGE-LIST-COUNT',
                BINGELIST_REMOVE_COUNT: 'BINGELIST-REMOVE-COUNT',
                LOGIN_PAGE: "LOGIN-PAGE",
                BINGE_PRIME_STATUS: 'BINGE-PRIME-STATUS',
                EXISTING_PRIME: 'EXISTING-PRIME',
                NUDGE_PAYMENT_TYPE: 'NUDGE-PAYMENT-TYPE',
                APPS: 'APPS',
                ACTIVATION_PENDING: 'ACTIVATION-PENDING',
                INTERSTITIAL_PAGE: 'NTERSTITIAL-PAGE',
                CHANNEL_NAME: "CHANNEL-NAME",
                X_DEV: "X-DEV",
                SOCKET_ID: "SOCKET-ID",
                DEVICE_ADDED: 'DEVICE-ADDED',
                DEVICE_REMOVED:'DEVICE-REMOVED',
        },
        ANALYTICS: "MIXPANEL",
};
