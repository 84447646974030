import React from "react";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import _ from "lodash";

import { ERROR_CODE, MESSAGE, PARAMS_TYPE, LOGIN_TYPE, LOCALSTORAGE, DTH_TYPE, CODE, TOAST_ID, PRIVATE_DEEPLINKS, SECTION_SOURCE } from "@constants";
import store from "@src/store";
import { closePopup, openPopup } from "@common/Modal/action";
import { MODALS } from "@common/Modal/constants";
import { initiateAppEventListener } from "@config/bootup";
import {
    getClientIPDetails,
    updateUserInfo,
    isUserloggedIn, callLogOut,
    showConfetti,
    getAnalyticsSource,
    safeNavigation,
    isMobile,
    isSubscriptionDiscount,
    handleLogoutAllDevices,
    showFibreWelcomePopup,
    isIspEnabledPlan,
    setSavedLanguages,
    addPartnerToContinueWatching,
    accountChannelToBeSubscribed,
    getLastActivityCapture,
    redirectToMangeApp,
    showAppleRenewNudge,
} from "@utils/common";
import { createNewBingeUser, updateBingeUser, forceLogout, setManualLoginSource, setLoginManual, updateLoginStep, closeLoginPopup, updateSilentLoginFailed, isZeroAppLogin } from "./APIs/actions";
import LoginNotNow from "@containers/Login/LoginNotNow";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import { setKey, getKey, deleteKey } from "@utils/storage";
import { URL } from "@constants/routeConstants";
import appsFlyerConfig from "@utils/appsFlyer";
import APPSFLYER from "@utils/constants/appsFlyer";
import { handleSubscriptionCall, isSubscriptionFreemium } from '@containers/Subscription/APIs/subscriptionCommon';
import { fromLoginLoader } from "@src/action";
import { rmnMaskingFunction, redirectToHomeScreen } from "@containers/BingeLogin/bingeLoginCommon";
import { openMiniSubscription, setUpdatedTenure, validateSelectedPack, clearPackList, getPlanSummaryUrl, getWebPortalBackLink, getCurrentSubscriptionInfo, getWebPortalLink } from "@containers/Subscription/APIs/action";
import googleConversionConfig from "@utils/googleCoversion";
import googleConversion from "@utils/constants/googleConversion";
import DeviceManagement from "@containers/DeviceManagement";
import mobile from "@assets/images/mobile.png";
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";
import { history } from "@utils/history";
import FIREBASE from "@utils/constants/firebase";
import SuccessTick from "@assets/images/Success-tick.png"
import { GET_OTP_LENGTH, VALIDATE_SOURCE, LENGTH_CHECK } from "@utils/constants";
import trackEvent from "../../utils/trackEvent";
import { SUBSCRIBED_MESSAGE } from './APIs/constants';

import { PROVIDER_EVENT_TYPE, PROVIDER_CW } from "@utils/constants/playerConstant";
import { setLiveUserLogin } from "@containers/PIDetail/API/actions";
import { JOURNEY_SOURCE } from "@containers/Subscription/APIs/constant";
import { checkNudgeVisiblity } from "@containers/RenewNudge/common";
import { getJWTTokenForEventRover, getLatestEventFromER } from "@components/Header/APIs/actions";
import { LOGIN_BEFORE_SUBSCRIBE } from "@constants";
import { handleContentClick } from "@common/ListingItem/listingItemCommon";

export const userLoginHandle = async (response, params) => {
    if (response.code === 0) {
        let state = store.getState();
        let forceLogoutRes = get(state.loginReducer, 'forceLogoutRes.data');
        if (!isEmpty(forceLogoutRes)) {
            response.data = {
                ...response?.data,
                ...forceLogoutRes,
            }
            // for (const [ key, value ] of Object.entries(forceLogoutRes)) {
            //     response.data[key] = value;
            // }
        }
        await updateUserInfo(response, params);
        initiatePubnubOnLogin(response);
        // mixPanelLoginSuccessEvents(params);
    } else {
        // mixPanelLoginFailEvents(response, params);
    }
};

const initiatePubnubOnLogin = async (response) => {
    accountChannelToBeSubscribed(response?.data) // Update account pubnub channel in local
    setKey(LOCALSTORAGE.ENABLE_EVENT_ROVER, JSON.stringify(response?.data?.enableEventRover));
    await fetchJWTTokenForEventRover();
    initiateAppEventListener(true);
}

export const trackMixpanelError = (message, code, source) => {
    let discountPageName = isSubscriptionDiscount(history) ? { [`${MIXPANEL.PARAMETER.PAGE_NAME}`]: MIXPANEL.VALUE.DISCOUNTING_PAGE } : {}
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_FAILURE, {
        [MIXPANEL.PARAMETER.ERROR]: message || MESSAGE.ERROR_OCCURRED,
        [MIXPANEL.PARAMETER.ERROR_CODE]: code || -1,
        [MIXPANEL.PARAMETER.SOURCE]: source,
        ...discountPageName
    });
}

export const errorForAPIFailure = (message, history, callBack) => {
    store.dispatch(openPopup(MODALS.ALERT_MODAL, {
        modalClass: 'alert-modal',
        headingMessage: 'Error Message',
        instructions: message ? message : MESSAGE.ERROR_OCCURRED,
        primaryButtonAction: () => {
            if (callBack) {
                callBack();
            } else {
                history && safeNavigation(history, URL.DEFAULT);
            }
        },
        primaryButtonText: 'Ok',
        errorIcon: 'icon-alert-upd',
        closeModal: true,
        hideCloseIcon: true,
    }));
}

export const updateUser = async (data, onLoginSuccess, history, source, callback, selectedPlan, onLoginFailureCallback) => {
    const { baId, dthStatus, subscriberId, bingeSubscriberId, loginErrorMessage, rmn, otp, isPastBingeUser,
        userAuthenticateToken, deviceAuthenticateToken, helpCenterSilentLogin, packageId, forceLogout, cartId, temporaryId, silentLoginEvent,
        showLoader = true, referenceId, loginBeforeSubscribeSource } = data;
    let isPrimeBannerClick = getKey(LOCALSTORAGE.PRIME_BANNER_CLICK);

    if (loginErrorMessage) {
        errorForAPIFailure(loginErrorMessage);
        trackEvent.loginFail(data)
        trackMixpanelError(loginErrorMessage, -1, source)
    } else {
        const params = {
            rmn,
            type: PARAMS_TYPE.LOGIN,
            userAuthenticateToken,
            deviceAuthenticateToken,
            helpCenterSilentLogin
        };
        const payload = {
            dthStatus,
            subscriberId,
            bingeSubscriberId,
            baId,
            login: LOGIN_TYPE.OTP,
            mobileNumber: rmn,
            isPastBingeUser,
            payment_return_url: `${window?.location?.origin}/${URL.SUBSCRIPTION_TRANSACTION_REDIRECT}`,
            eulaChecked: true,
            packageId,
            cartId,
            temporaryId,
            silentLoginEvent,
            referenceId,
            ...(isPrimeBannerClick && { journeySource: JOURNEY_SOURCE.PRIME_BANNER }),
            loginBeforeSubscribeSource
        };
        /* when we are passing platform as WEB_SMALL , then at backend that device is not getting registered
        And device remove popup appear for such cases when pubnub push is receive
        to avoid device removal in these case we are storing "BE_REGISTERED_DEVICE" in local storage */
        setKey(LOCALSTORAGE.BE_REGISTERED_DEVICE, JSON.stringify(helpCenterSilentLogin));
        await store.dispatch(updateBingeUser(payload, params, helpCenterSilentLogin, showLoader)).then(async existingUser => {
            if (existingUser?.code === 0) {
                onLoginSuccess && onLoginSuccess(existingUser, data, history, source, callback, selectedPlan, cartId, loginBeforeSubscribeSource);
                trackEvent.loginSuccess(data);
                trackEvent.completeRegistration();
            } else {
                if (existingUser?.code === 200007) {
                    mixPanelConfig.trackEvent(MIXPANEL.EVENT.MAX_DEVICE_LIMIT);
                    // mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_LIMIT_POPUP, {
                    //     [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.LOGIN,
                    // });
                    deviceLimitExceededPopup(
                        existingUser,
                        data,
                        history,
                        source,
                        callback,
                        loginBeforeSubscribeSource
                    );
                }
                else if (existingUser?.code === ERROR_CODE.ERROR_130007) {
                    const { message, title = "Force Logout" } = existingUser;
                    handleLogoutAllDevices(title, message);
                    trackMixpanelError(existingUser?.message, existingUser?.code);
                }
                else {
                    if (get(data, 'silentLoginTimestamp')) {
                        let state = store.getState();
                        let shouldHandleSilentLoginError = !get(state.loginReducer, 'silentLoginFailed');
                        store.dispatch(updateSilentLoginFailed(true));
                        shouldHandleSilentLoginError && updateUser(data, onLoginSuccess, history);
                    } else {
                        existingUser?.response?.status !== CODE.CODE_200 && forceLogout && callLogOut(true, history);
                        existingUser?.response?.status !== ERROR_CODE.ERROR_500 && errorForAPIFailure(existingUser?.message, history, onLoginFailureCallback)
                        store.dispatch(setUpdatedTenure());
                    }
                    trackMixpanelError(existingUser?.message, existingUser?.code);
                }
                trackEvent.loginFail(data);
            }
        }).catch(error => {
            console.log(error, 'error on login')
        });
    }
}

export const createUser = async (data, onLoginSuccess, history, source, callback, selectedPlan, onLoginFailureCallback) => {
    // baIld : If exist for DTH_WITHOUT_BINGE
    const { loginErrorMessage, rmn, otp, subscriberId, dthStatus, baId, userAuthenticateToken, deviceAuthenticateToken,
        helpCenterSilentLogin, isPastBingeUser, packageId, referenceId, forceLogout, cartId, loginBeforeSubscribeSource } = data;
    let isPrimeBannerClick = getKey(LOCALSTORAGE.PRIME_BANNER_CLICK);

    const payload = {
        dthStatus,
        subscriberId,
        login: LOGIN_TYPE.OTP,
        mobileNumber: rmn,
        baId,
        isPastBingeUser,
        eulaChecked: true,
        packageId,
        referenceId,
        source,
        cartId,
        ...(isPrimeBannerClick && { journeySource: JOURNEY_SOURCE.PRIME_BANNER }),
        loginBeforeSubscribeSource
    };
    const params = { rmn, type: PARAMS_TYPE.LOGIN, userAuthenticateToken, deviceAuthenticateToken };

    await getClientIPDetails();
    if (loginErrorMessage) {
        errorForAPIFailure(loginErrorMessage);
        trackEvent.loginFail(data);
        trackMixpanelError(loginErrorMessage, -1, source);
    } else {
        trackEvent.loginSuccess(data);
        trackEvent.completeRegistration();
        await store.dispatch(createNewBingeUser(payload, params, helpCenterSilentLogin)).then(async newUser => {
            if (newUser?.code === 0) {
                onLoginSuccess && onLoginSuccess(newUser, data, history, source, callback, selectedPlan, cartId, loginBeforeSubscribeSource);
            } else {
                store.dispatch(setUpdatedTenure());
                newUser?.response?.status !== CODE.CODE_200 && forceLogout && callLogOut(true, history);
                newUser?.response?.status !== ERROR_CODE.ERROR_500 && errorForAPIFailure(newUser?.message, history, onLoginFailureCallback);
                trackEvent.loginFail(data);
                trackMixpanelError(newUser?.message, newUser?.code, source);
            }
        });
    }
}

export const notNow = async (props, notNowSource) => {
    let paramData = new URLSearchParams(window.location.search);
    let status = paramData.get('status');
    let cartId = paramData.get('cartId');
    let state = store.getState();
    const loginSource = get(state.loginReducer, "manualLoginSource");

    if (props?.isFromCampaign) {
        history.back()
    }
    else if (status === 'login') {
        await store.dispatch(getWebPortalBackLink(cartId));
    } else {
        handleLoginClose(props)
        loginInNotSuccessFulToast();
    }
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_PAGE_NOTNOW, {
        [MIXPANEL.PARAMETER.SOURCE]: loginSource,
    })
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_EXIT, {
        [MIXPANEL.PARAMETER.SOURCE]: loginSource,
        [MIXPANEL.PARAMETER.LOGIN_PAGE]: notNowSource
    })
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_SKIP);
    dataLayerConfig.trackEvent(DATALAYER.EVENT.NOT_NOW_LOGIN_JOURNEY)
}

export const loginInNotSuccessFulToast = () => {
    toast(<LoginNotNow />, {
        autoClose: 3000,
        toastId: TOAST_ID.LOGIN_NOT_NOW_TOAST,
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnFocusLoss: false,
        className: "login-fail login-toast-wrapper",
    })
}

export const licenceAgreement = () => {
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_LIC_AGREEMENT);
}

export const LoginSuccessToast = ({ message = "", freeTrailMessage = "", isFreeTrail = false }) => {
    const messageToShow = isFreeTrail ? freeTrailMessage : message;
    return (
        <div className="login-body-container">
            <div className="login-success-image">
                <img src={SuccessTick} alt="" />
            </div>
            <div className="login-success-text">
                <div>{messageToShow}</div>
                {isFreeTrail && <div className="free-trail">Free Trial Started!</div>}
            </div>
        </div>
    );
};

const toastHandler = (loginFreeTrialAvailed) => {
    let state = store.getState(), userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)),
        currentSubscriptionInfo = get(state.subscriptionDetails, 'currentSubscription.data', {}),
        successResponse = get(state.headerDetails, "configResponse.data.config.device.success");
    let { loginToastMessage } = userInfo?.subscriptionStatusInfo;

    if (isIspEnabledPlan() && get(currentSubscriptionInfo, 'showFibreMsg')) { // Hide login toast and welcome message is shown
        return false;
    }

    toast(
        <LoginSuccessToast
            isFreeTrail={loginFreeTrialAvailed}
            message={loginToastMessage || successResponse || SUBSCRIBED_MESSAGE.SUCCESSFULLY_LOGGED_IN}
            freeTrailMessage="Successfully Logged in."
        />,
        {
            position: toast.POSITION.BOTTOM_CENTER,
            className: `login-toast-wrapper login-border ${loginFreeTrialAvailed ? "free-trial-toast" : ""}`,
            autoClose: 2000
        },
    );
}

export const isOldBingeUser = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    let anonymousId = getKey(LOCALSTORAGE.ANONYMOUS_ID);
    let isOldUserloggedIn = isUserloggedIn();
    // let isDthStatusEmpty = isEmpty(userInfo?.dthStatus)
    let isDthStatusEmpty = [DTH_TYPE.DTH_W_BINGE_OLD_USER, DTH_TYPE.DTH_W_BINGE_NEW_USER, DTH_TYPE.DTH_W_BINGE_USER, DTH_TYPE.DTH_WO_BINGE_USER, DTH_TYPE.NON_DTH_USER].includes(userInfo?.dthStatus);
    return isOldUserloggedIn && !anonymousId && !isDthStatusEmpty;
}

// export const loginOldBingeUserInFreemium = async (history, counter = 0) => {
//     await store.dispatch(forceLogout());
//     let state = store.getState();
//     let forceLogoutRes = get(state.loginReducer, 'forceLogoutRes.data');
//     if (forceLogoutRes) {
//         let dthStatus = get(forceLogoutRes, 'dthStatus');
//         let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
//         const data = {
//             baId: get(userInfo, "baId"),
//             bingeSubscriberId: null,
//             dthStatus: get(forceLogoutRes, "dthStatus"),
//             subscriberId: get(userInfo, "sId"),
//             rmn: get(forceLogoutRes, "rmn"),
//             otp: get(forceLogoutRes, "otp"),
//             userAuthenticateToken: get(userInfo, "accessToken"),
//             deviceAuthenticateToken: get(userInfo, "deviceToken"),
//             helpCenterSilentLogin: false,
//             isPastBingeUser: get(forceLogout, 'isPastBingeUser'),
//             referenceId: get(forceLogoutRes, 'referenceId'),
//             forceLogout: true,
//             // packageId: updatedTenure?.tenureId||"",
//         };
//         if (dthStatus === DTH_TYPE.DTH_WO_BINGE_USER) {
//             await createUser(data, onLoginSuccess, history);
//         } else {
//             await updateUser(data, onLoginSuccess, history);
//         }
//     } else if (counter <= 2) {
//         counter += 1
//         await loginOldBingeUserInFreemium(history, counter);
//     } else {
//         // Fallback for API failure
//     }
// }

export const isDetailPage = (history) => {
    const urlArr = history?.location?.pathname.split("/");
    return [URL.DETAIL].includes(urlArr[1])
}

export const onLoginSuccess = async (response, value, history, source = FIREBASE.VALUE.HOME, callback = () => {
}, selectedPlan, cartId, loginBeforeSubscribeSource) => {
    let loginFreeTrialAvailed = response?.data?.loginFreeTrialAvailed;
    if (isSubscriptionDiscount(history) || (isMobile.any() && history.location?.state?.prevPath === `/${URL.SUBSCRIPTION_DISCOUNT}`)) {
        isMobile.any() && safeNavigation(history, URL.SUBSCRIPTION_DISCOUNT)
        callback();
        toastHandler(loginFreeTrialAvailed);
    } else if (source === VALIDATE_SOURCE.COUPON_REDEEM) {
        store.dispatch(onManualLogin(MIXPANEL.VALUE.COUPON_REDEEM_PAGE));
        store.dispatch(closePopup());
        safeNavigation(history, {
            pathname: `/${URL.COUPON_REDEEM}`,
            state: { isCouponLoginSuccess: true },
        });
    } else if (get(value, 'silentLoginTimestamp')) {
        setKey(LOCALSTORAGE.SILENT_LOGIN_TIMESTAMP, value?.silentLoginTimestamp);
        get(value, 'isAnyWhereFromTikTik') && setKey(LOCALSTORAGE.START_WATCHING_NOW, true)
        store.dispatch(openPopup(MODALS.ALERT_MODAL, {
            imageUrl: `../../assets/images/Success-tick.png`,
            modalClass: "alert-modal silent-login-success",
            headingMessage: 'Account Updated Successfully',
            primaryButtonText: 'Ok',
            hideCloseIcon: true,
            primaryButtonAction: async (bingeCheck, primeCheck, newHistory) => {
                redirectToHomeScreen(!isEmpty(newHistory) ? newHistory : history);
            },
        }));
    } else {
        deleteKey(LOCALSTORAGE.HOME_PAGE_LAUNCH_COUNTER);
        deleteKey(LOCALSTORAGE.SHOW_SUBSCRIPTION_POPUP_COUNTER);
        deleteKey(LOCALSTORAGE.NUDGE_LAUNCH_COUNTER);
        await store.dispatch(getCurrentSubscriptionInfo()).then(async (response) => {
            response?.data?.appSelectionRequired && store.dispatch(isZeroAppLogin(true));
            store.dispatch(setManualLoginSource(source));
            store.dispatch(setLoginManual(true));
            let provider = PROVIDER_CW.ALL;
            await addPartnerToContinueWatching(PROVIDER_EVENT_TYPE.LOGIN_SUCCESS, provider);
        });
        let urlArr = history?.location?.pathname.split("/"),
            isLoginFromLivePiPage = isDetailPage(history) && ['live'].includes(urlArr[2]);
        isLoginFromLivePiPage && store.dispatch(setLiveUserLogin(true))
        setSavedLanguages(); // Set preferred lang in local just after user login
        nativeLoginFlow(response, value, history, source, callback = () => { }, selectedPlan, cartId, loginBeforeSubscribeSource)
        window.scrollTo(0, 0);
        trackEventsOnLoginSuccess(response, value, history, source);
        store.dispatch(closePopup());
        store.dispatch(openMiniSubscription());
        await showFibreWelcomePopup(history);
        setTimeout(() => {
            checkNudgeVisiblity()
        }, 4000);
        setTimeout(() => {
            showAppleRenewNudge(history?.location)
        }, 4000);
    }
};

export const trackEventsOnLoginSuccess = (response, value, history, source = FIREBASE.VALUE.HOME) => {
    let loginFreeTrialAvailed = response?.data?.loginFreeTrialAvailed,
        state = store.getState(),
        newUser = get(state.loginReducer, "newUser");
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.SET_DEFAULT_PROFILE);
    loginFreeTrialAvailed && showConfetti(3 * 1000);
    source = source === FIREBASE.VALUE.PLAY_CLICK ? FIREBASE.VALUE.PLAYBACK : source
    if (loginFreeTrialAvailed) {
        let mixpanelData = {
            [`${MIXPANEL.PARAMETER.SOURCE}`]: getAnalyticsSource(history.location.pathname) || '',
        };
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.START_FREE_TRIAL, mixpanelData);
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.FREE_TRIAL_SUCCESS, {
            [APPSFLYER.PARAMETER.SOURCE]: source,
        });
    }
    let appsFlyerData = {
        [APPSFLYER.PARAMETER.TYPE]: APPSFLYER.VALUE.RMN,
        [APPSFLYER.PARAMETER.AUTH]: APPSFLYER.VALUE.OTP,
        [APPSFLYER.PARAMETER.VALUE]: rmnMaskingFunction(value.rmn),
        [APPSFLYER.PARAMETER.SOURCE]: source,
    }


    if (newUser && !_.isEmpty(newUser)) {
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.SIGN_UP, appsFlyerData);
        trackEvent.signUpEvent(getFireBaseData({ ...value, source }))
    } else {
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.LOGIN_SUCCESS, appsFlyerData);
        googleConversionConfig.trackEvent(googleConversion.EVENT.LOGIN_SUCCESS)
    }
}

export const updateLocalStorage = (response) => {
    setKey(LOCALSTORAGE.ANONYMOUS_ID, get(response, "data.anonymousId"));
    setKey(LOCALSTORAGE.G_AUTH_TOKEN, get(response, "data.gAuthToken"));
}

const deviceLimitExceededPopup = (existingUserRes, data, history, source, closeLoginPopup, onDeviceRemoved) => {
    const state = {
        data: data,
        source: source,
        isBeforeLogin: true,
    }
    let storeState = store.getState();
    let deviceData = get(storeState.headerDetails, "configResponse.data.config")
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_LIMIT_REVIEW, {
        [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.LOGIN,
    });
    if (!isMobile.any()) {
        DeviceRemovalModal(onDeviceRemoved, existingUserRes?.subMessage, state)
    } else {
        closeLoginPopup && closeLoginPopup()
        safeNavigation(history, {
            pathname: `/${URL.DEVICE_MANAGEMENT}`,
            state: state
        }
        );
    }
    // store.dispatch(openPopup(MODALS.ALERT_MODAL, {
    //     imageUrl: mobile,
    //     modalClass: "device-limit-popup",
    //     headingMessage: get(deviceData, "device.headers") || existingUserRes.message,
    //     instructions: get(deviceData, "device.subHeader") || existingUserRes?.subMessage,
    //     primaryButtonText: get(deviceData, "device.review") || `Review Devices`,
    //     hideCloseIcon: true,
    //     isCloseModal: isMobile.any(),
    //     primaryButtonAction: () => {
    //         mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_LIMIT_REVIEW, {
    //             [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.LOGIN,
    //         });
    //         if (!isMobile.any()) {
    //             DeviceRemovalModal(onDeviceRemoved, existingUserRes?.subMessage, state)
    //         } else {
    //             closeLoginPopup && closeLoginPopup()
    //             safeNavigation(history, {
    //                 pathname: `/${URL.DEVICE_MANAGEMENT}`,
    //                 state: state
    //             }
    //             );
    //         }
    //     },
    //     secondaryButtonText: get(deviceData, "notNow") || "Not now",
    //     secondaryButtonAction: () => {
    //         mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_LIMIT_SKIP, {
    //             [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.LOGIN,
    //         })
    //         notNow(state, MIXPANEL.VALUE.LOGIN_DEVICELIMITPOPUP);
    //     },
    // }))
}

const DeviceRemovalModal = (onDeviceRemoved, subMessage, state) => {
    store.dispatch(
        openPopup(MODALS.CUSTOM_MODAL, {
            modalClass: "device-listing-popup",
            childComponent: (
                <DeviceManagement isBeforeLogin={true} subTitle={subMessage} onDeviceRemoved={onDeviceRemoved}
                    pathState={state} />
            ),
            hideCloseIcon: true,
        }),
    );
}

export const handleLoginClose = (props) => {
    let paramData = new URLSearchParams(window.location.search),
        action = paramData.get('action'),
        isAppsFlyerDeeplinkUrl = PRIVATE_DEEPLINKS.includes(action),
        state = store.getState();
    store.dispatch(closePopup());
    store.dispatch(openMiniSubscription());
    isAppsFlyerDeeplinkUrl && props?.history && safeNavigation(props.history, URL.DEFAULT);

    if (state?.loginReducer?.showLoginPopup) {
        store.dispatch(closeLoginPopup());
        store.dispatch(updateLoginStep(1));
    }
};

export const getFireBaseData = (value) => {
    return {
        [FIREBASE.PARAMETER.TYPE]: FIREBASE.VALUE.RMN,
        [FIREBASE.PARAMETER.AUTH]: FIREBASE.VALUE.OTP,
        [FIREBASE.PARAMETER.VALUE]: rmnMaskingFunction(value.rmn),
        [FIREBASE.PARAMETER.SOURCE]: value?.source,
    }
}

export const nativeLoginFlow = (response, value, history, source = FIREBASE.VALUE.HOME, callback = () => { }, selectedPlan, cartId, loginBeforeSubscribeSource) => {
    let loginFreeTrialAvailed = response?.data?.loginFreeTrialAvailed,
        state = store.getState(),
        isManagedApp = get(state.headerDetails, "isManagedApp"),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        isFromCampaign = history.location?.state?.prevPath?.includes(URL.SUBSCRIPTION_CAMPAIGN) && history?.location?.state?.isTenure && isManagedApp;
    if (!isManagedApp || isFromCampaign) {
        miniLoginSuccess(userInfo, loginFreeTrialAvailed, history, callback, selectedPlan)
    } else {
        tickTickLoginSuccess(userInfo, loginFreeTrialAvailed, history, callback, cartId, loginBeforeSubscribeSource, source, value)
    }
}


export const miniLoginSuccess = async (userInfo, loginFreeTrialAvailed, history, callback, selectedPlan) => {
    let state = store.getState(),
        isNonFreemiumDthUser = !isSubscriptionFreemium() && userInfo?.dthStatus !== DTH_TYPE.NON_DTH_USER, // non freemium and non dth user
        updatedTenure = get(state.subscriptionDetails, 'selectedTenureValue'),
        newUser = get(state.loginReducer, "newUser");
    if (userInfo?.subscriptionStatusInfo) {
        let { loginToastFlag, nonSubscribedToSamePack, allowPG } = userInfo?.subscriptionStatusInfo;
        if (!updatedTenure || isEmpty(updatedTenure) || loginToastFlag) {
            // normal login flow with toast message
            if (!isDetailPage(history)) {
                if (history.location?.state?.isFromPi) {
                    setKey(LOCALSTORAGE.PI_DETAIL_URL, history.location.state.url);
                    setKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI, `true`);
                    safeNavigation(history, `${history.location?.state?.url}`);
                } else {
                    safeNavigation(history, URL.DEFAULT);
                }
                loginToastFlag && toastHandler(loginFreeTrialAvailed);
            }
            callback();
        } else if (nonSubscribedToSamePack) {
            callback();
            await store.dispatch(clearPackList())
            safeNavigation(history, {
                pathname: URL.SUBSCRIPTION,
                state: { tenureOpen: true, selectedPlan: selectedPlan },
            });
            toastHandler(loginFreeTrialAvailed);
        } else if (allowPG) {
            if (isNonFreemiumDthUser) {
                // if user is non-freemium i.e subscriptionType !== FREEMIUM and non dth user,
                // then in this case add and modify will be called on Make payment button click on balane info page
                setKey(LOCALSTORAGE.IS_NON_FREEMIUM_NON_DTH_SUBSCRIPTION_FLOW, true);
                safeNavigation(history, `/${URL.BALANCE_INFO}`);
                // this.handleLoginClose();
                callback();
                toastHandler(loginFreeTrialAvailed);
            } else {
                // pack validate API will be called only for FREEMIUM users.
                store.dispatch(fromLoginLoader(true))
                setTimeout(async () => {
                    isSubscriptionFreemium() && await store.dispatch(validateSelectedPack(updatedTenure?.tenureId));
                    await handleSubscriptionCall(history, true)
                    store.dispatch(fromLoginLoader(false))
                    callback();
                    toastHandler(loginFreeTrialAvailed);
                }, 4000)
            }
        } else {
            callback();
            !isDetailPage(history) && safeNavigation(history, URL.DEFAULT);
        }
    } else {
        callback();
        !isDetailPage(history) && safeNavigation(history, URL.DEFAULT);
    }

    let { loginToastFlag, allowPG, nonSubscribedToSamePack } = userInfo?.subscriptionStatusInfo;
    if ((!loginToastFlag && !allowPG && !nonSubscribedToSamePack) || (!isEmpty(newUser) && isEmpty(updatedTenure) && !loginToastFlag && !nonSubscribedToSamePack)) {
        toastHandler(loginFreeTrialAvailed);
    }

}

export const tickTickLoginSuccess = async (userInfo, loginFreeTrialAvailed, history, callback, cartId, loginBeforeSubscribeSource, source, value) => {
    let isPrimeBannerClick = getKey(LOCALSTORAGE.PRIME_BANNER_CLICK);
    if (userInfo?.subscriptionStatusInfo) {
        let { loginToastFlag, nonSubscribedToSamePack, allowPG, shouldOpenPackListing } =
            userInfo?.subscriptionStatusInfo;
        if (!shouldOpenPackListing && isPrimeBannerClick) {
            redirectToDefaultHome(history)
        } else if ([LOGIN_BEFORE_SUBSCRIBE.CONTENT_CARD_CLICK, LOGIN_BEFORE_SUBSCRIBE.MID_SCROLL].includes(loginBeforeSubscribeSource) && !isEmpty(value?.contentData)) {
            const { event, props } = value.contentData
            handleContentClick(event, props)
        } else if (shouldOpenPackListing) {
            redirectToPackListing(loginBeforeSubscribeSource, source, value)
        } else if (loginToastFlag) {
            // normal login flow with toast message            
            if (!isDetailPage(history)) {
                if (history.location?.state?.isFromPi) {
                    setKey(LOCALSTORAGE.PI_DETAIL_URL, history.location.state.url);
                    setKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI, `true`);
                    safeNavigation(history, `${history.location?.state?.url}`)
                } else {
                    redirectToDefaultHome(history)
                }
                toastHandler(loginFreeTrialAvailed);
            }
        } else if (nonSubscribedToSamePack) {
            toastHandler(loginFreeTrialAvailed);
            cartId && (await store.dispatch(getPlanSummaryUrl(cartId)));
        } else if (allowPG) {
            toastHandler(loginFreeTrialAvailed);
            cartId && (await store.dispatch(getPlanSummaryUrl(cartId)));
            !cartId && redirectToDefaultHome(history)
        } else {
            let state = store.getState();
            !isDetailPage(history) && redirectToDefaultHome(history);
            isDetailPage(history) && state?.PIDetails?.isLiveUserLogin && store.dispatch(setLiveUserLogin(false));
            toastHandler(loginFreeTrialAvailed);
        }
        window.onpopstate = () => { } // TSF-26258 | After succeefull login to stop user from goining back to manage app on back CTA click
        deleteKey(LOCALSTORAGE.PRIME_BANNER_CLICK)
    }
    getLastActivityCapture()
    callback();
};


/**
 * @function getOtpLength - to get lenngth of otp based on backend data for login screen or FS activate screen
 * @param {*} source - value string for origin of OTP request
 * @returns - otp length
 */
export const getOtpLength = (source) => {
    let state = store.getState();
    const otpLengthConfig = get(state.headerDetails, 'configResponse.data.config.newOtpFlow');
    const otpSource = source === VALIDATE_SOURCE.SMART_TV ? VALIDATE_SOURCE.SMART_TV : otpLengthConfig;
    const otpLength = GET_OTP_LENGTH.find(i => i.key === otpSource);
    return otpLength?.value ? otpLength?.value : LENGTH_CHECK.OTP;
}

const redirectToDefaultHome = (history) => { // This function is responsible to re-render the home component after login
    let path = history.location.pathname;
    const pathHolderArr = path.toString().split('/');
    let isBBLBBGPage = pathHolderArr.includes(URL.BROWSE_BY_GENRE) || pathHolderArr.includes(URL.BROWSE_BY_LANGUAGE);
    if (!isSeeALLPage(history)) {
        if (isBBLBBGPage) {
            safeNavigation(history, URL.DEFAULT);
        } else if (history?.location?.pathname?.includes(URL.HOME)) {
            safeNavigation(history, URL.DEFAULT);
        } else if (history?.location?.pathname.includes(URL.DEFAULT)) {
            safeNavigation(history, URL.HOME);
        } else {
            safeNavigation(history, URL.DEFAULT);
        }
    }
}

const isSeeALLPage = (history) => {
    const { location } = history
    return location?.pathname.includes(URL.SEE_ALL)
}

export const fetchJWTTokenForEventRover = async (retries = 8, delay = 1000) => {
    try {
        const response = await store.dispatch(getJWTTokenForEventRover());
        if (response?.token) {
            return response;
        } else {
            throw new Error(`JWT Token not recieved`);
        }
    } catch (error) {
        if (retries > 0) {
            console.log(`Event Rover JWT Token retrying in ${delay / 1000} seconds... Retries left: ${retries}`);
            await new Promise((resolve) => setTimeout(resolve, delay));
            return fetchJWTTokenForEventRover(retries - 1, delay * 2);
        } else {
            console.log("Max retries reached while fetching JWT Token for Event rover");
            return fetchJWTTokenForEventRover(8, 1000);
        }
    }
}

export const fetchLatestEventFromEventRover = async (login) => {
    let state = store.getState();
    let newUserDetail = get(state.loginReducer, 'newUser.data'),
        existingUserDeatil = get(state.loginReducer, 'existingUser.data');

    return new Promise(async (resolve) => {
        setTimeout(async () => {
            let pubnubReceived = JSON.parse(getKey(LOCALSTORAGE.PUBNUB_RECEIVED));
            let callHistoryOnLogin = !pubnubReceived && (!isEmpty(newUserDetail) || !isEmpty(existingUserDeatil));
            if (!login || callHistoryOnLogin) {
                const response = await store.dispatch(getLatestEventFromER());
                resolve(response);
            }
        }, login ? 5000 : 0);
    })
}

const redirectToPackListing = async (loginBeforeSubscribeSource, source, value) => {
    if ([LOGIN_BEFORE_SUBSCRIBE.SUBSCRIBE].includes(loginBeforeSubscribeSource)) {
        redirectToMangeApp(source)
    } else if ([LOGIN_BEFORE_SUBSCRIBE.PI_SUBSCRIBE].includes(loginBeforeSubscribeSource)) {
        let journeySourceRefId = getKey(LOCALSTORAGE.JOURNEY_SOURCE_REF_ID)
        await store.dispatch(getWebPortalLink({ initiateSubscription: JOURNEY_SOURCE.CONTENT_PLAY, journeySource: JOURNEY_SOURCE.HOME_CONTENT, journeySourceRefId: journeySourceRefId, analyticSource: MIXPANEL.VALUE.CONTENT_PLAYBACK }))
    } else if ([LOGIN_BEFORE_SUBSCRIBE.MARKETING_DRAWER_LOGIN_CTA].includes(loginBeforeSubscribeSource)) {
        value?.handleSubscription()
    }
}