import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CrossIcon from "@assets/images/cross.png";
import RightArrow from "@assets/images/arrow-right-pink.svg";
import "./style.scss";
import { get } from "lodash";
import { setAppleRenewnudge } from "@containers/Subscription/APIs/action";
import { appleRedirection } from "@utils/common";
import MIXPANEL from "@constants/mixpanel";

export const ActivateAppleNudge = (verbiages) => {
  const dispatch = useDispatch();
  const currentSubscription = useSelector((state) =>
    get(state.subscriptionDetails, "currentSubscription.data")
  );
  const showNudge = useSelector((state) =>
    get(state.subscriptionDetails, "appleRenewNudge")
  );
  const showRenewNudge = useSelector((state) =>
    get(state.subscriptionDetails, "renewNudgeStatus")
  );

  const onNudgeClick = (isCloseModal = false) => {
    if (isCloseModal) {
      dispatch(setAppleRenewnudge(false));
    } else {
      appleRedirection(
        "",
        MIXPANEL.VALUE.HOME_NUDGE,
        MIXPANEL.VALUE.HOME_NUDGE,
        true
      );
    }
    dispatch(setAppleRenewnudge(false));
  };

  const nudgeTitle = get(
      currentSubscription?.appleSubscriptionNudgeDetails,
      "header"
    ),
    nudgeMessage = get(
      currentSubscription?.appleSubscriptionNudgeDetails,
      "message"
    ),
    nudgeCTA = get(
      currentSubscription?.appleSubscriptionNudgeDetails,
      "button"
    ),
    renewIcon = get(
      currentSubscription?.appleSubscriptionNudgeDetails,
      "logoImage"
    );
  const isShowNudge = currentSubscription?.appleSubscriptionNudgeDetails && showNudge && !showRenewNudge;

  return (
    <>
      {isShowNudge && (
        <div className="renew-nudge-container">
          <span className="img-container">
            <img src={renewIcon} alt="icon" />
          </span>
          <div className="text-container">
            <p>{nudgeTitle}</p>
            <p>{nudgeMessage}</p>
            <span onClick={() => onNudgeClick()}>
              <p>{nudgeCTA}</p>
              <img src={RightArrow} alt="icon" />
            </span>
          </div>
          <div className="cross-icon-container">
            <div className="cross-icon" onClick={() => onNudgeClick(true)}>
              <img src={CrossIcon} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
